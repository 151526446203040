.homepageNav{
    background-color:rgba(255,255,255,.4) !important;
    /* z-index: 1200; */
    box-shadow:none;
    font-size:17px;
    justify-content: space-between !important;
    padding: 0.5rem 1rem !important;
}


.homepageNav>div>div>div a{
    color:#0F5B93  !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.homepageNav>div a{
    color:#0F5B93  !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.homepageNav>div>div>div >div >a{
    color:rgb(53, 53, 56) !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.homepageNav>div>div>div a::after{
   content:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA30lEQVQ4je2OPw/BQBjGn2t6ldS/EpZ+CYvVwmYTacRmOpvN4EswGc43MAkiWCy+j5D0hp6ktbS00YsYJZ7pcu/7+z0v8E8iBWdathk3VXObcbPgTMvxPy16WIOZRSg9ukIeqs48lwYL4W2IQU/5Pq+8CYK7PgFBnQANSf11/BKbcVO4cguQFoCapslxNCPPCsZpUcglgA4ABMA5I7U2Lem+EN4mhEGA3fUSdLEfeUlBigQBTmFNM9xY3bJGD4vh/f0CleS1mGhWC1IkKlgtiEkIYKjgz2GcgnH6PfhTeQBLbFnG/k9TJgAAAABJRU5ErkJggg==");
    vertical-align:-0.05em;
     border:none;
     margin-top:0px;
}
.homepageNav>div>div>div >div a::after{
    content: "";
     vertical-align: middle;
     border:none;
     margin-top:0px;
}

.main_logo{
    width:220px;
}
.trust_logo{
    height: 50px;
     width: 55px;
}
.normalNav{
 background: linear-gradient(240deg , rgba(38,170,225,1) 0%, rgba(3,67,144,1) 100%) !important;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    margin-bottom: 0;
    /* z-index: 1200; */
    font-size:17px;
    justify-content: space-between !important;
    padding: 0.5rem 1rem !important;
}
.normalNav>div>div>div a{
    color:white !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.normalNav>div a{
    color:white !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.normalNav>div>div>div >div >a{
    color:rgb(53, 53, 56) !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.normalNav>div>div>div a::after{
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAdklEQVQ4je3QsQ6CQBCEYRpiQigI8aV5GSpKrqG18Y0+G43H5RaxNHHa2flnd5vmr50wojvwO4yROeCGDX0QXnHHtQaYvJXyTZ7hlPlTDdBizoY29FnzSwsu0RklJBXNM9roRxHkc/MJyPlwBfJ9uIAc3/z7egC+KuGQHgx9zAAAAABJRU5ErkJggg==");
     vertical-align: -0.05em;
     border:none;
     margin-top:0px;
}
.normalNav>div>div>div >div a::after{
    content: "";
     vertical-align: middle;
     border:none;
     margin-top:0px;
}
.buttonLogin > button{
    background-color: transparent;
        min-width: 10vw;
        box-shadow: none;
        color: white;
        border: transparent;
         border-radius: 25px;
}
.buttonLogin > button:hover{
    background: #fff;
    color: #0481b5;
}

.homepageButtonApply{
    margin-top: -6px;
    border: 1px solid  #0481b5;
     min-width: 12vw;
     color: #fff;
     background-color: #0481b5;
    border-radius: 20px;
min-width: 10vw;
box-shadow: none;
}
.buttonApply{
    margin-top: -6px;
    border: 1px solid #fff;
     min-width: 12vw;
    background: #fff;
    color: #0481b5;
    border-radius: 20px;
min-width: 10vw;
box-shadow: none;
}
.buttonApply :hover{
    border:1px solid #fff;
    background: #fff;
    color: #0481b5;
    

}
.buttonApply :focus{

    background: #fff;
    color: #0481b5;
    box-shadow:none;
}
.buttonApply :active{
    background: #fff;
    color: #0481b5;
    box-shadow:none;
}

.buttonApply:hover{
    border:1px solid #fff;
    background: #fff;
    color: #0481b5;
    

}
.buttonLogout{
    margin-top: -6px;
    border: 1px solid #fff;
     min-width: 12vw;
    background: #fff;
    color: #0481b5;
    border-radius: 20px;
min-width: 10vw;
box-shadow: none;
}

.buttonLogout :focus{
    background: #fff;
    color: #0481b5;
    box-shadow:none;
}
.buttonLogout :active{
    background: #fff;
    color: #0481b5;
    box-shadow:none;
}

.buttonLogout:hover{
    border:1px solid #fff;
    background: #fff;
    color: #0481b5;
}


.buttonStart > button:hover{
    background-color: #fff !important;
    color: #0481b5;
    border:1px solid #fff;
}

.buttonStart > button{
    margin-top: 0px;
    border: 1px solid #fff;
     min-width: 12vw;
    background: #fff;
    color: #0481b5;
    border-radius: 20px;
}

.buttonStart > button:focus{
    
    background: #fff;
    color: #0481b5;
    box-shadow:none;
}
.buttonStart > button:active{
  
    background: #fff;
    color: #0481b5;
    box-shadow:none;
}


.buttonRes{
    margin-top: -6px;
    background: transparent;
    border: transparent;
    border-radius: 25px;
    min-width: auto;
    box-shadow: none;
    margin-left: -1vw; 

}
.itemExt:hover{
    color: #16181b;
text-decoration: none;
background-color: #f8f9fa !important;
}
.buttonRes:focus{
    background-color:transparent;
}
.buttonRes:hover{
    background: #fff;
    color: #0481b5;
}

.sub_link{
    text-decoration:none;
    color: #16181b;
    padding:10px;
    width:100%;
}
.sub_link:hover{
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}
@media(min-width:990px) and (max-width:1023px){
    .homepageNav>div a{
        transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
        font-size:11px !important;
    }
    .normalNav>div a{
        font-size:11px !important;
    }
    .homepageNav>div>div>div a::after{
        vertical-align:-0.35em;
         margin-top:-2px;
    }
    .normalNav>div>div>div a::after{
        vertical-align: -0.35em;
        margin-top:-2px;
   }
    /* .homepageNav{
        z-index: 1200;
        box-shadow:none;
       font-size:13px !important;
    }    */
}
@media(min-width:1024px) and (max-width:1199px){
    .homepageNav>div a{
        transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
        font-size:11px !important;
    }
    .normalNav>div a{
        font-size:11px !important;
    }
    .homepageNav>div>div>div a::after{
         vertical-align:-0.35em;
          margin-top:-2px;
     }
     .normalNav>div>div>div a::after{
         vertical-align: -0.35em;
         margin-top:-2px;
    }
   
}
@media(max-width:767px){
    .homepageNav{
        background: linear-gradient(240deg , rgba(38,170,225,1) 0%, rgba(3,67,144,1) 100%) !important;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    margin-bottom: 0;
    transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    z-index: 1200;
    }
    .homepageNav>div>div>div a{
        color:white !important;
        transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
    .buttonApply > button{
    margin-left:-5px;
    }

    .buttonStart > button{
        margin-left: -5px;
    }
    .main_logo{
        width:210px;
    }
    .trust_logo{
        width:45px;
        height:40px;
    }
    .homepageNav>div a{
        color:white  !important;
        transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    .homepageNav>div>div>div a::after{
        content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAdklEQVQ4je3QsQ6CQBCEYRpiQigI8aV5GSpKrqG18Y0+G43H5RaxNHHa2flnd5vmr50wojvwO4yROeCGDX0QXnHHtQaYvJXyTZ7hlPlTDdBizoY29FnzSwsu0RklJBXNM9roRxHkc/MJyPlwBfJ9uIAc3/z7egC+KuGQHgx9zAAAAABJRU5ErkJggg==");
        vertical-align:-0.05em;
         border:none;
         margin-top:0px;
    }

}

@media(max-width:399px){
    .normalNav{
       background: linear-gradient(240deg , rgba(38,170,225,1) 0%, rgba(3,67,144,1) 100%) !important;
       box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
       margin-bottom: 0;
       font-size:17px;
       justify-content: space-between !important;
       padding: 0.5rem 0.2rem !important;
   }
}

@media (min-width:768px) and (max-width:991px){
    .main_logo{
        margin-top: 8px;
    }
}
@media(max-width:768px){
    .main_logo{
 
        margin-top: 2px;
    }
}
@media(max-width:340px){
    .main_logo{
        width:170px;
        margin-top: 8px;
    }
}


